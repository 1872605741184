import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { convertToUTCDate, utcToLocal } from '../../common/utils/helpers'
import { connectResource } from '../../common/utils/resource'
import NumberInput from '../../common/forms/inputs/NumberInput'
import TextInput from '../../common/forms/inputs/TextInput'
import DatePickerInput from '../../common/forms/inputs/DatePickerInput'
import RadiosInput from '../../common/forms/inputs/RadiosInput'
import { PAYMENT_TYPES } from '../../common/constants/borrowerPaymentOptions'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap'
import Loader from '../../common/layout/components/Loader'
import moment, { isMoment } from 'moment'

class CreatePaymentModal extends Component {
  state = {
    amount: this.props.editing ? this.props.payment_amount : this.props.total_payout_due,
    description: this.props.default_description,
    payment_type: this.props.default_payment_type,
    date_to_be_sent: this.props.date_to_be_sent ? utcToLocal(this.props.date_to_be_sent) : new Date(),
    ramp_invoice_number: this.props.ramp_invoice_number || moment().format('MMDDYYYY'),
    trackingUser: {
      user: `${this.props.analytics_data.firstname} ${this.props.analytics_data.lastname}`,
      role: this.props.analytics_data.role.name,
      lender: this.props.analytics_data.lender.name,
      component: this.constructor.name,
    },
  }


  componentDidMount = () => {
    // Segment tracking
    this.props.analytics_track_component('Opened Modal', this.state.trackingUser)
  }

  getDateTimeToBeSent = (date_to_be_sent) => {
    let dateToSend
    const dateToday = new Date()

    if (isMoment(date_to_be_sent)) {
      date_to_be_sent = date_to_be_sent.toDate()
    }

    const dateSelectedWithoutTime = `${date_to_be_sent.getFullYear()}/${date_to_be_sent.getMonth()}/${date_to_be_sent.getDate()}`
    const dateTodayWithoutTime = `${dateToday.getFullYear()}/${dateToday.getMonth()}/${dateToday.getDate()}`

    if (dateSelectedWithoutTime === dateTodayWithoutTime) {
      dateToSend = dateToday
    } else {
      dateToSend = convertToUTCDate(date_to_be_sent)
    }
    return dateToSend
  }

  getPaymentData = () => {
    const { analytics_data, editing, is_internal_reggora_user } = this.props
    const {
      amount,
      description,
      date_to_be_sent,
      payment_type,
      ramp_invoice_number,
    } = this.state

    const paymentData = {
      amount: parseInt(amount * 100),
      description,
    }

    if (!editing) {
      paymentData.payment_type = payment_type
      paymentData.date_to_be_sent = new Date()

      if (is_internal_reggora_user) {
        paymentData.internal_user_id = analytics_data.id
      }
    }

    if (payment_type === 'external') {
      if (is_internal_reggora_user) {
        paymentData.ramp_invoice_number = ramp_invoice_number
      } else {
        paymentData.date_to_be_sent = this.getDateTimeToBeSent(date_to_be_sent)
      }
    }

    return paymentData
  }

  onSubmit = () => {
    const {
      paymentId,
      paymentEndpoint,
      refreshOrder,
      editing,
      analytics_track_component,
    } = this.props

    const payment = this.getPaymentData()

    if (editing) {
      // Segment Tracking
      analytics_track_component('Submitted An Edit', this.state.trackingUser)
      paymentEndpoint.put({ payment, payment_id: paymentId }).then(refreshOrder)
    } else {
      // Segment Tracking
      analytics_track_component('Submitted Create Payment', this.state.trackingUser)
      paymentEndpoint.create({ payment }).then(refreshOrder)
    }
  }

  hasMoreThanTwoDecimals = (number) => {
    const numStr = number.toString()
    const decimalIndex = numStr.indexOf('.')

    if (decimalIndex === -1) {
      return false
    }

    const decimalPart = numStr.slice(decimalIndex + 1)
    return decimalPart.length > 2
  }

  onAmountChange = (amount) => {
    if (this.hasMoreThanTwoDecimals(amount)) {
      return
    }
    this.setState({ amount })
  }

  isInvalidPayment = (outstandingBalance) => {
    const { is_internal_reggora_user } = this.props
    const {
      amount,
      payment_type,
      ramp_invoice_number,
    } = this.state

    if (!amount || Number(amount) === 0 || amount > outstandingBalance) {
      return true
    }

    if (payment_type === 'stripe' && amount < 0) {
      return true
    }

    if (is_internal_reggora_user && payment_type === 'external' && !ramp_invoice_number) {
      return true
    }

    return false
  }

  render() {
    const {
      onHide,
      editing,
      paymentEndpoint,
      total_payout_due,
      payment_amount,
      default_payment_type,
      default_manual_status,
      is_internal_reggora_user,
    } = this.props
    const {
      amount,
      description,
      payment_type,
      date_to_be_sent,
      ramp_invoice_number,
    } = this.state

    const submitBtnTxt = editing ? 'Edit Payment' : (
      payment_type !== 'external' ? 'Initiate Transfer' : 'Submit')

    const headerText = editing ? 'Edit Payment' : 'Send Funds to Vendor'

    let outstandingBalance = total_payout_due
    if (editing && default_payment_type === 'external' && default_manual_status === 'paid') {
      outstandingBalance += payment_amount
    }

    return (<Fragment>
      {(paymentEndpoint.isLoading) && <Loader />}
      <ModalHeader toggle={onHide}>{headerText}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label>Payment Description</Label>
              <TextInput
                placeholder="Description"
                name={'description'}
                value={description}
                onChange={description => this.setState({ description })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label>Amount</Label>
              <NumberInput
                placeholder="Amount"
                name={'amount'}
                value={amount}
                onChange={this.onAmountChange}
              />
              {Boolean(!amount || Number(amount) === 0) && <span className='text-danger'>Required</span>}
              {Boolean(amount && amount > outstandingBalance) && <span className='text-danger'>
                Transfer amount cannot exceed available balance: ${outstandingBalance.toFixed(2)}
              </span>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label>Payment Type</Label>
              <RadiosInput
                disabled={editing}
                name={'payment_type'}
                value={payment_type}
                options={PAYMENT_TYPES}
                onChange={payment_type => this.setState({ payment_type })}
              />
            </FormGroup>
          </Col>
        </Row>
        {payment_type === 'external' && !is_internal_reggora_user &&
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Date</Label>
                <DatePickerInput
                  className='w-50 ant-input'
                  value={date_to_be_sent}
                  onChange={date_to_be_sent => this.setState({ date_to_be_sent })}
                />
                <i className="fa fa-calendar" style={{ position: 'absolute', top: '50%', right: '52%', pointerEvents: 'none', color: 'darkgray' }}/>
              </FormGroup>
            </Col>
          </Row>}
        {payment_type === 'external' && is_internal_reggora_user &&
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Ramp Invoice Number</Label>
                <TextInput
                  placeholder="Ramp Invoice Number"
                  name={'ramp_invoice_number'}
                  value={ramp_invoice_number}
                  onChange={ramp_invoice_number => this.setState({ ramp_invoice_number })}
                />
                {!ramp_invoice_number && <span className='text-danger'>Required</span>}
              </FormGroup>
            </Col>
          </Row>}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
        <Button disabled={this.isInvalidPayment(outstandingBalance)} color='primary' onClick={this.onSubmit}>{submitBtnTxt}</Button>
      </ModalFooter>
    </Fragment>)
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'paymentEndpoint',
    endpoint: 'order/payment/:id?',
    successMessage: {
      POST: 'Your payment has been added.',
      PUT: 'Your payment has been updated.',
    },
    apiVersion: 2,
  }),
)(CreatePaymentModal)
