import React, { useState, useEffect, useCallback } from 'react'
import { Tooltip } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import moment from 'moment'
import Rephrase from '../../../common/utils/Rephrase'
import { utcToLocal } from '../../../common/utils/helpers'

const SubmissionDeliveryStatusIcon = (props) => {
  const { hasAutoSendProduct, submission, canShowConsumerDelivery, sendBorrowerAppraisal, autoSendBorrowerAppraisal } =
    props

  const [autoSendToBorrowerStatus, setAutoSendToBorrowerStatus] = useState('')
  const [autoSendToBorrowerStatusColor, setAutoSendToBorrowerStatusColor] = useState('text-muted')

  const setSubmissionAutoSendStatus = useCallback(() => {
    const convertToLocalTime = (time) => {
      return moment(utcToLocal(time)).format('h:mm a, MMM Do YYYY')
    }

    const canDisplayAutoSendToBorrowerStatus = () => {
      return canShowConsumerDelivery && sendBorrowerAppraisal && autoSendBorrowerAppraisal && hasAutoSendProduct
    }

    if (!canDisplayAutoSendToBorrowerStatus()) {
      return
    }

    if (submission.auto_send_to_borrower_blocked) {
      setAutoSendToBorrowerStatus('Appraisal automatic sending stopped')
      setAutoSendToBorrowerStatusColor('text-danger')
      return
    }

    // Check if the appraisal has already been sent to the borrower
    if (submission.auto_sent_to_borrower) {
      const autoSentToBorrowerTime = convertToLocalTime(submission.auto_sent_to_borrower_time)
      setAutoSendToBorrowerStatus(`Automatically sent appraisal to borrower at ${autoSentToBorrowerTime}`)
      setAutoSendToBorrowerStatusColor('text-success')
      return
    }

    if (submission.scheduled_send_time) {
      const scheduledSendTime = convertToLocalTime(submission.scheduled_send_time)
      setAutoSendToBorrowerStatus(`Automatically sending appraisal to borrower at ${scheduledSendTime}`)
      setAutoSendToBorrowerStatusColor('text-muted')
    }
  }, [hasAutoSendProduct, submission, canShowConsumerDelivery, sendBorrowerAppraisal, autoSendBorrowerAppraisal])

  useEffect(() => {
    setSubmissionAutoSendStatus()
  }, [submission, canShowConsumerDelivery, setSubmissionAutoSendStatus])

  return autoSendToBorrowerStatus ? (
    <Tooltip
      className="autosend-tooltip"
      placement="bottomLeft"
      title={<Rephrase>{autoSendToBorrowerStatus}</Rephrase>}
    >
      <ExportOutlined className={`${autoSendToBorrowerStatusColor} ml-2`} />
    </Tooltip>
  ) : (
    <React.Fragment />
  )
}

export default SubmissionDeliveryStatusIcon
