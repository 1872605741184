import { checkPermission } from '../../../common/utils/helpers'
import { JOB_TYPES } from '../../../common/constants/jobTypes'
import {
  ESCALATION_ALERT_TEXT,
  FEE_ESCALATION_AND_NEW_DUE_DATE,
  FEE_ESCALATION,
  NEW_DUE_DATE,
} from '../../../common/constants/alerts'
import { COUNTEROFFER_OPTIONS, NO_REASON_PROVIDED } from '../../../../reggora_shared_ui/constants/counterOfferVariables'

export function getWarningText(
  appraiser_name,
  previous_amount,
  new_amount,
  disclosed_amount,
  borrower_fee_delta,
  borrowerPaymentAmount,
  n_processing,
  totalDue,
  totalPaid,
  offer,
  job_type,
  lender_attention_required_reason,
  dueDate,
  newDueDate,
  currentUser = {},
  reggora_amc_management_fee_dollars,
  is_amc_lender,
) {
  let warningText
  let hasPermission
  if (job_type === 'va') {
    hasPermission = checkPermission('user_view_va_appraiser')
  } else {
    hasPermission = checkPermission('user_view_appraiser')
  }
  if (!hasPermission) {
    appraiser_name = 'A vendor'
  }

  const amcOrderNonAmcLender = job_type === JOB_TYPES.AMC && !is_amc_lender
  // Determine if order has escalations
  let dueDateWarningText = ''
  let feeWarningText = ''
  const hasDueDate = dueDate.format('MM/DD/YYYY') !== newDueDate.format('MM/DD/YYYY')
  if (hasDueDate) {
    const updatedDueDate = dueDate
    const updatedNewDueDate = newDueDate
    dueDateWarningText = ` from ${updatedDueDate.format('MM/DD/YYYY')} to ${updatedNewDueDate.format('MM/DD/YYYY')}`
  }
  const hasNewFee = previous_amount.toFixed(2) !== new_amount.toFixed(2)
  let previousAmountToDisplay = previous_amount
  let newAmountToDisplay = new_amount
  const {
    reason,
    is_pending_client_lender: isPendingClientLender,
  } = offer
  if (hasNewFee) {
    // Show original amount / original AMP fee and new amount / new AMP fee for client lender
    // and if AMP has already forwarded
    if (amcOrderNonAmcLender || isPendingClientLender) {
      const { new_reggora_amc_management_fee } = offer || {}
      const newAmpFee = Number.isInteger(parseInt(new_reggora_amc_management_fee, 10))
        ? new_reggora_amc_management_fee
        : reggora_amc_management_fee_dollars
      previousAmountToDisplay = previous_amount + reggora_amc_management_fee_dollars
      newAmountToDisplay = new_amount + newAmpFee
    }
    feeWarningText = `update the order fee from $${previousAmountToDisplay.toFixed(2)} to $${newAmountToDisplay.toFixed(2)}`

    // Add AMP fee text if offer has not been forwarded for client lender
    if (is_amc_lender && !offer.is_pending_client_lender) {
      feeWarningText = `${feeWarningText} (+ $${reggora_amc_management_fee_dollars} AMP fee)`
    }
  }

  // Determine escalation warningType
  let warningType = 'fee escalation'
  if (hasDueDate && hasNewFee && lender_attention_required_reason === FEE_ESCALATION_AND_NEW_DUE_DATE) {
    dueDateWarningText = ' and new due date request' + dueDateWarningText
  } else {
    warningType = ESCALATION_ALERT_TEXT[lender_attention_required_reason]
  }

  const nameToDisplay = amcOrderNonAmcLender ? 'The Appraisal Marketplace' : appraiser_name

  const initialWarningText = `Warning: ${nameToDisplay} has initiated`
  if (n_processing > 1) {
    warningText = `${initialWarningText} ${n_processing} unprocessed ${warningType}s ${feeWarningText}${dueDateWarningText}. The order will not progress until these escalations have been reconciled.`
  } else if (offer.status === 'pending') { // this text accompanies accept / deny buttons
    warningText = `${initialWarningText} a ${warningType} ${feeWarningText}${dueDateWarningText}.
    The order will not progress until this escalation has been reconciled.`
  }

  /* if the outstanding balance after a payment is more than 0
    AND the offer is still processing, keep showing payment options + warning text
  */
  // Determine user's payment settings
  let stripePaymentEnabled = false
  if (currentUser && currentUser.lender.stripe_payments_enabled) {
    stripePaymentEnabled = true
  }
  const outstanding_balance = newAmountToDisplay - totalPaid
  if (outstanding_balance > 0 && offer.status === 'processing' && stripePaymentEnabled) {
    warningText = `${initialWarningText} to ${feeWarningText},
      of which $${totalPaid.toFixed(2)} has been paid so far. Please select an option to pay the remaining $${outstanding_balance.toFixed(2)}.
      The order will not progress until this escalation has been reconciled.`
  }

  if (disclosed_amount && stripePaymentEnabled) {
    if (borrower_fee_delta < new_amount - previous_amount) {
      if (borrower_fee_delta > 0) {
        // The borrower can still be charged, but cannot cover the full cost of the escalation
        warningText += ` This loan's disclosed amount is $${disclosed_amount.toFixed(2)}, of which the borrower has paid $${(borrowerPaymentAmount).toFixed(2)} so far. As such, you may charge the borrower up
                to an additional $${borrower_fee_delta.toFixed(2)} as part of this reconciliation.`
      } else {
        // The borrower has paid the entirety of the disclosed amount
        warningText += ' The borrower has already paid the entirety of the disclosed amount, so you may not charge them any extra as part of this reconciliation.'
      }
    } else {
      // The borrower can cover the full cost of the fee escalation
      warningText += ` This loan's disclosed amount is $${disclosed_amount.toFixed(2)}, of which the borrower has paid $${(borrowerPaymentAmount).toFixed(2)} so far. As such, you may charge the borrower
            the full amount needed to cover the cost of this ${warningType}.`
    }
  }

  // Add vendor reason if the offer is NOT pending client lender
  if (reason && !isPendingClientLender) {
    const parsedReason = COUNTEROFFER_OPTIONS[reason] || reason.substring(0, 200) || NO_REASON_PROVIDED
    warningText += ` Vendor Reason(s): ${parsedReason}`
  }

  if (offer.amp_notes) {
    warningText += ` AMP Notes: ${offer.amp_notes}`
  }

  return warningText
}

export function getPaymentTypeAvailability(userPaymentTypes, type) {
  let available = false
  userPaymentTypes.forEach(paymentType => {
    if (paymentType.value === type) {
      available = true
    }
  })

  return available
}

export function checkEditAcceptDenyPermission(lender_attention_required_reason) {
  let canEditAcceptDenyOffer = false
  if (lender_attention_required_reason === FEE_ESCALATION &&
    checkPermission('order_allow_fee_escalation_accept_deny')) {
    canEditAcceptDenyOffer = true
  }
  if (lender_attention_required_reason === NEW_DUE_DATE &&
    checkPermission('order_allow_due_date_request_accept_deny')) {
    canEditAcceptDenyOffer = true
  }

  if (lender_attention_required_reason === FEE_ESCALATION_AND_NEW_DUE_DATE &&
    checkPermission('order_allow_due_date_request_accept_deny') &&
    checkPermission('order_allow_fee_escalation_accept_deny')) {
    canEditAcceptDenyOffer = true
  }
  return canEditAcceptDenyOffer
}
